import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import {
  SupplierTableItemFragment,
  useSupplierUpsertMutation,
} from "../../../__generated__/types-and-hooks";
import { useTeam } from "../TeamLayout";
import { useTranslation } from "../../../i18n";
import { useNotify } from "../../../hooks/useNotify";
import EditArrayCell from "../../../components/datagrid/EditArrayCell";
import ArrayCell from "../../../components/datagrid/ArrayCell";
import { Box, Chip, IconButton, Tooltip } from "@mui/joy";
import ContactTooltip from "./ContactTooltip";
import PersonIcon from "@mui/icons-material/Person";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SupplierRef from "../../../components/SupplierRef";
import ContactsModal from "./ContactsModal";

export type TableViewSupplier = any;

export interface TableViewProps {
  suppliers: TableViewSupplier[];
}

function ContactRenderCell({
  onEditContactsClick,
  ...params
}: { onEditContactsClick: (row: any) => void } & GridRenderCellParams) {
  return (
    <Box sx={{ display: "flex", p: 0.5 }}>
      <Box gap={1} sx={{ display: "flex", overflow: "hidden", flex: 1 }}>
        {params.row.contacts.map((contact: any) => (
          <Tooltip
            enterDelay={500}
            leaveDelay={200}
            key={contact.key}
            variant="outlined"
            arrow
            title={
              <ContactTooltip uniqueId={contact.uniqueId} name={contact.name} />
            }
          >
            <Chip
              variant="outlined"
              sx={{ borderRadius: 4 }}
              startDecorator={<PersonIcon fontSize="small" />}
            >
              {contact.name}
            </Chip>
          </Tooltip>
        ))}
      </Box>
      <IconButton
        sx={{ fontSize: 12 }}
        size="sm"
        color="primary"
        variant="plain"
        onClick={() => onEditContactsClick(params.row)}
      >
        <ModeEditIcon style={{ fontSize: 14 }} />
      </IconButton>
    </Box>
  );
}

export default function TableView({ suppliers }: TableViewProps) {
  const t = useTranslation();
  const teamCtx = useTeam();
  const team = teamCtx.team;
  const notify = useNotify();

  function RefRenderCell({
    teamSlug,
    ...params
  }: { teamSlug: string } & GridRenderCellParams<any, string>) {
    return (
      <SupplierRef supplierRef={params.row.uniqueId} teamSlug={teamSlug} />
    );
  }

  const [contactsModalOpen, setContactsModalOpen] =
    useState<SupplierTableItemFragment | null>(null);

  function onEditContactsClick(row: SupplierTableItemFragment) {
    setContactsModalOpen(row);
  }

  const [supplierUpsertMutation] = useSupplierUpsertMutation({});
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        width: 10,
        editable: false,
      },
      {
        field: "uniqueId",
        headerName: t("Ref"),
        width: 180,
        editable: false,
        renderCell: (params) => (
          <RefRenderCell {...params} teamSlug={team.slug} />
        ),
      },
      {
        field: "name",
        headerName: t("Name"),
        width: 200,
        editable: true,
      },
      {
        field: "capabilities",
        headerName: t("Capabilities"),
        width: 200,
        editable: true,
        renderCell: (params) => <ArrayCell {...params} />,
        renderEditCell: (params) => <EditArrayCell {...params} />,
      },
      {
        field: "contacts",
        headerName: t("Contacts"),
        width: 420,
        editable: false,
        renderCell: (params) => (
          <ContactRenderCell
            {...params}
            onEditContactsClick={onEditContactsClick}
          />
        ),
      },
      {
        field: "addressLine1",
        headerName: t("Address Line 1"),
        width: 200,
        editable: true,
      },
      {
        field: "addressLine2",
        headerName: t("Address Line 2"),
        width: 100,
        editable: true,
      },
      {
        field: "city",
        headerName: t("City"),
        width: 100,
        editable: true,
      },
      {
        field: "state",
        headerName: t("State"),
        width: 100,
        editable: true,
      },
      {
        field: "postalCode",
        headerName: t("Postal Code"),
        width: 100,
        editable: true,
      },
      {
        field: "countryCode",
        headerName: t("Country"),
        width: 100,
        editable: true,
      },
    ],
    [t, team.slug]
  );

  const handleProcessRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      { rowId }: { rowId: GridRowId }
    ) => {
      const delta = (field: string, key?: string) =>
        newRow[field] !== oldRow[field]
          ? typeof key === "string" && typeof newRow[field] === "object"
            ? newRow[field][key]
            : newRow[field]
          : void 0;

      const res = await supplierUpsertMutation({
        variables: {
          supplier: {
            teamId: teamCtx.team.id,
            id: rowId as number,
            name: delta("name"),
            addressLine1: delta("addressLine1"),
            addressLine2: delta("addressLine2"),
            city: delta("city"),
            state: delta("state"),
            postalCode: delta("postalCode"),
            countryCode: delta("countryCode"),
            capabilities: newRow.capabilities ?? oldRow.capabilities,
          },
        },
      });
      if (res.errors) {
        throw new Error(res.errors.map((e) => e.message).join("\n"));
      }
      return newRow;
    },
    [supplierUpsertMutation, teamCtx]
  );

  return (
    <>
      <DataGrid
        rows={suppliers}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={42}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={(err) => {
          notify({
            type: "danger",
            msg: err.message,
          });
        }}
      />
      {contactsModalOpen && (
        <ContactsModal
          supplier={contactsModalOpen}
          onClose={() => setContactsModalOpen(null)}
        />
      )}
    </>
  );
}
