import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "../../../i18n";
import {
  SupplierTableItemFragment,
  useSupplierUpsertMutation,
} from "../../../__generated__/types-and-hooks";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useState } from "react";
import { ContactAutocomplete } from "../../../components/ContactAutocomplete";
import { useTeam } from "../TeamLayout";

export interface ContactsModalProps {
  supplier: SupplierTableItemFragment;
  onClose: () => void;
}

export default function ContactsModal({
  supplier,
  onClose,
}: ContactsModalProps) {
  const teamCtx = useTeam();
  const [contacts, setContacts] = useState<
    ({ id: number; name: string } | null)[]
  >(supplier.contacts ? [...supplier.contacts] : []);
  const [supplierUpsertMutation] = useSupplierUpsertMutation();
  const t = useTranslation();
  const handleAdd = useCallback(() => {
    setContacts([...contacts, null]);
  }, [contacts]);
  const handleSave = useCallback(() => {
    supplierUpsertMutation({
      variables: {
        supplier: {
          teamId: teamCtx.team.id,
          id: supplier.id,
          contacts: contacts.filter((c) => !!c).map((c) => c!.id),
        },
      },
      onCompleted() {
        onClose();
      },
    });
  }, [contacts, supplierUpsertMutation, teamCtx.team.id, supplier.id, onClose]);
  const handleRemove = useCallback(
    (index: number) => {
      contacts.splice(index, 1);
      setContacts([...contacts]);
    },
    [contacts]
  );

  return (
    <Modal open onClose={onClose}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {supplier.name} - {t("Contacts")}
        </DialogTitle>
        <ModalClose onClick={onClose} />
        <Typography
          level="body-xs"
          sx={{ marginTop: 1, wordWrap: "break-word" }}
        >
          {t(
            "Use the dropdown to select the contact assiciated with a given supplier."
          )}
          <br />
          {t(
            'Add new contacts as needed, or click "Remove" to delete existing ones.'
          )}
        </Typography>
        <Stack spacing={2} sx={{ minHeight: 300 }}>
          {contacts.map((contact, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <ContactAutocomplete
                contact={contact}
                teamId={teamCtx.team.id}
                onChange={(contact) => {
                  contacts[index] = contact;
                  setContacts([...contacts]);
                }}
              />
              <Button
                color="danger"
                variant="soft"
                onClick={() => handleRemove(index)}
                startDecorator={<DeleteOutlineOutlinedIcon />}
                sx={{
                  ml: 2,
                  display: { xs: "none", sm: "flex" },
                }}
              >
                {t("Remove")}
              </Button>
              <IconButton
                color="danger"
                variant="soft"
                onClick={() => handleRemove(index)}
                sx={{
                  ml: 2,
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          ))}
          <Box>
            <Button
              variant="soft"
              onClick={handleAdd}
              startDecorator={<AddIcon />}
            >
              {t("Add")}
            </Button>
          </Box>
        </Stack>
        <Button type="submit" onClick={handleSave}>
          {t("Save")}
        </Button>
      </ModalDialog>
    </Modal>
  );
}
