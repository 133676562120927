import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../i18n";
import { ReactNode, useState } from "react";
import {
  ContactTableItemFragment,
  useContactUpsertMutation,
} from "../../../__generated__/types-and-hooks";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTeam } from "../TeamLayout";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export interface ContactMethodsModalProps {
  contact: ContactTableItemFragment;
  onClose: () => void;
}

const iconMap: Record<string, ReactNode> = {
  phone: <Phone fontSize="small" />,
  email: <Email fontSize="small" />,
  whatsapp: <WhatsApp fontSize="small" />,
  other: <Message fontSize="small" />,
};

export default function ContactMethodsModal({
  contact,
  onClose,
}: ContactMethodsModalProps) {
  const t = useTranslation();
  const [contactMethods, setContactMethods] = useState([
    ...contact.contactMethods.map((cm) => ({ key: cm.key, value: cm.value })),
  ]);
  if (contactMethods.length === 0) {
    contactMethods.push({ key: "phone", value: "" });
  }
  const handleChange = (index: number, key: string, value: string) => {
    const updatedMethods = [...contactMethods];
    (updatedMethods[index] as any)[key] = value;
    setContactMethods(updatedMethods);
  };
  const handleRemove = (index: number) => {
    const arr = [...contactMethods];
    arr.splice(index, 1);
    setContactMethods(arr);
  };
  const handleAdd = () => {
    const arr = [...contactMethods];
    arr.push({ key: "phone", value: "" });
    setContactMethods(arr);
  };

  const [contactUpsertMutation] = useContactUpsertMutation({});
  const teamCtx = useTeam();
  const handleSave = async () => {
    const res = await contactUpsertMutation({
      variables: {
        contact: {
          teamId: teamCtx.team.id,
          id: contact.id,
          contactMethods: contactMethods.map((cm) => ({
            key: cm.key,
            value: cm.value,
          })),
        },
      },
      update(cache, res) {
        cache.modify({
          id: cache.identify({
            __typename: contact.__typename,
            id: contact.id,
          }), // Locate the cache entry for the item
          fields: {
            contactMethods() {
              return res.data?.contact_upsert.contactMethods;
            },
          },
        });
      },
    });
    if (res.errors) {
      throw new Error(res.errors.map((e) => e.message).join("\n"));
    }
    onClose();
  };

  return (
    <Modal open onClose={onClose}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {contact.name} - {t("Contact Methods")}
        </DialogTitle>
        <ModalClose onClick={onClose} />
        <Typography
          level="body-xs"
          sx={{ marginTop: 1, wordWrap: "break-word" }}
        >
          {t(
            "Use the dropdown to select the type (e.g., phone, email, WhatsApp) and provide the relevant details."
          )}
          <br />
          {t(
            'Add new methods as needed, or click "Remove" to delete existing ones.'
          )}
        </Typography>
        <Stack spacing={2} sx={{ minHeight: 300 }}>
          {contactMethods.map((method, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <Select
                startDecorator={
                  iconMap[method.key] ?? <Message fontSize="small" />
                }
                value={method.key}
                onChange={(_e, newValue) =>
                  handleChange(index, "key", newValue!)
                }
                sx={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
                renderValue={(option) => (
                  <Typography
                    sx={{
                      textAlign: "left",
                      width: { xs: "auto", sm: 80 },
                      display: { xs: "none", sm: "block" },
                    }}
                  >
                    {option?.label}
                  </Typography>
                )}
              >
                <Option value="phone">
                  <Typography startDecorator={<Phone fontSize="small" />}>
                    {t("Phone")}
                  </Typography>
                </Option>
                <Option value="email">
                  <Typography startDecorator={<Email fontSize="small" />}>
                    {t("Email")}
                  </Typography>
                </Option>
                <Option value="whatsapp">
                  <Typography startDecorator={<WhatsApp fontSize="small" />}>
                    {t("WhatsApp")}
                  </Typography>
                </Option>
                <Option value="other">
                  <Typography startDecorator={<Message fontSize="small" />}>
                    {t("Other")}
                  </Typography>
                </Option>
              </Select>
              <Input
                type="text"
                value={method.value}
                sx={{
                  flex: 1,
                  borderLeft: 0,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                onChange={(e) => handleChange(index, "value", e.target.value)}
                endDecorator={
                  <IconButton color="primary" variant="soft">
                    <ContentCopyIcon
                      onClick={() =>
                        navigator.clipboard.writeText(method.value)
                      }
                    />
                  </IconButton>
                }
              />
              <Button
                color="danger"
                variant="soft"
                onClick={() => handleRemove(index)}
                startDecorator={<DeleteOutlineOutlinedIcon />}
                sx={{
                  ml: 2,
                  display: { xs: "none", sm: "flex" },
                }}
              >
                {t("Remove")}
              </Button>
              <IconButton
                color="danger"
                variant="soft"
                onClick={() => handleRemove(index)}
                sx={{
                  ml: 2,
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          ))}
          <Box>
            <Button
              variant="soft"
              onClick={handleAdd}
              startDecorator={<AddIcon />}
            >
              {t("Add")}
            </Button>
          </Box>
        </Stack>
        <Button type="submit" onClick={handleSave}>
          {t("Save")}
        </Button>
      </ModalDialog>
    </Modal>
  );
}
