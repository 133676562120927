import { useState } from "react";
import { IconButton, Link, Tooltip, Typography } from "@mui/joy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "../i18n";
import OpenInNewRounded from "@mui/icons-material/OpenInNewRounded";
import { Link as RouterLink } from "react-router-dom";

export interface SupplierRefProps {
  supplierRef: string;
  teamSlug: string;
  openInNewWindow?: boolean;
  copy?: boolean;
}

export default function SupplierRef({
  supplierRef,
  teamSlug,
  openInNewWindow,
  copy,
}: SupplierRefProps) {
  const t = useTranslation();
  const [copied, setCopied] = useState<string | null>(null);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(supplierRef);
    setCopied(supplierRef);
    setTimeout(() => setCopied(null), 200);
  };
  return (
    <Typography
      fontSize={14}
      startDecorator={
        copy && (
          <Tooltip arrow title={<Typography>{t("Copy Ref #")}</Typography>}>
            <IconButton
              size="sm"
              variant={copied === supplierRef ? "solid" : "plain"}
              color={copied === supplierRef ? "success" : "neutral"}
              onClick={handleCopyToClipboard}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <Link
        component={RouterLink}
        to={`/${teamSlug}/suppliers/${supplierRef}`}
        target={openInNewWindow ? "_blank" : ""}
        endDecorator={openInNewWindow ? <OpenInNewRounded /> : null}
      >
        {supplierRef && supplierRef.toUpperCase()}
      </Link>
    </Typography>
  );
}
