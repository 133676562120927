import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import { ListItemContent, ListItemDecorator, Typography } from "@mui/joy";
import {
  AutocompleteContactFragment,
  useAutocompleteContactsQuery,
} from "../__generated__/types-and-hooks";
import SearchIcon from "@mui/icons-material/Search";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";

const iconMap: Record<string, React.ReactNode> = {
  phone: <Phone style={{ fontSize: 14 }} />,
  email: <Email style={{ fontSize: 14 }} />,
  whatsapp: <WhatsApp style={{ fontSize: 14 }} />,
};

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  console.log("dataset", dataSet);
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      <ListItemDecorator>
        <Typography level="body-xs">#{dataSet[1].id}</Typography>
      </ListItemDecorator>
      <ListItemContent sx={{ fontSize: "sm" }}>
        <Typography level="body-sm" textColor="text.primary" noWrap>
          {dataSet[1]?.name}
          <Typography level="body-sm" noWrap startDecorator={"|"}>
            {dataSet[1].city} {dataSet[1].state} {dataSet[1].countryCode}
          </Typography>
        </Typography>

        <Typography level="body-xs" noWrap>
          {(dataSet[1]?.contactMethods ?? [])
            .filter(({ value }: any) => value !== "")
            .slice(0, 2)
            .map((method: any, index: number) => (
              <Typography
                key={index}
                startDecorator={
                  iconMap[method.key] ?? <Message style={{ fontSize: 14 }} />
                }
              >
                {method.value}
              </Typography>
            ))}
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 99999 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface ContactAutocompleteProps {
  hasFocus?: boolean;
  teamId: number;
  contact: { id: number; name: string } | null;
  onChange: (contact: AutocompleteContactFragment | null) => void;
}

export function ContactAutocomplete({
  hasFocus = false,
  contact,
  teamId,
  onChange,
}: ContactAutocompleteProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);
  const contactsQuery = useAutocompleteContactsQuery({
    variables: { teamId },
  });

  const handleChanges = React.useCallback(
    (e: any, value: AutocompleteContactFragment | null) => {
      onChange(value);
    },
    [onChange]
  );

  const isLoading = contactsQuery.loading;
  const contacts = contactsQuery.data?.contact_list?.contacts;

  return (
    <Autocomplete
      size="sm"
      sx={{ minWidth: "250px", width: "100%", fontSize: "14px", border: 0 }}
      value={contacts?.find((s) => s.id === contact?.id) ?? contact}
      loading={isLoading}
      disableListWrap
      startDecorator={<SearchIcon />}
      placeholder="Contacts..."
      onChange={handleChanges as any}
      slotProps={{
        input: {
          ref: inputRef,
        },
      }}
      slots={{
        listbox: ListboxComponent,
      }}
      options={contacts ?? []}
      groupBy={(option) => option.name[0].toUpperCase()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
      // filterOptions={filterOptions}
    />
  );
}
