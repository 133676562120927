import { Box, Chip, Divider, Stack, Typography } from "@mui/joy";
import { useContactTooltipByUniqueIdQuery } from "../../../__generated__/types-and-hooks";
import { ReactNode } from "react";
import CountryFlag from "../../../components/CountryFlag";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";
import ContactRef from "../../../components/ContactRef";
import { useTeam } from "../TeamLayout";

// Map of contact method keys to corresponding icons
const iconMap: Record<string, ReactNode> = {
  phone: <Phone style={{ fontSize: 14 }} />,
  email: <Email style={{ fontSize: 14 }} />,
  whatsapp: <WhatsApp style={{ fontSize: 14 }} />,
};

export interface ContactTooltipProps {
  uniqueId: string;
  name: string;
}

export default function ContactTooltip({
  uniqueId,
  name,
}: ContactTooltipProps) {
  const teamCtx = useTeam();
  const contactQuery = useContactTooltipByUniqueIdQuery({
    variables: { uniqueId: uniqueId! },
  });

  const contact = contactQuery.data && contactQuery.data.contact_by_id;
  return (
    <Box
      gap={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "auto",
        maxWidth: 320,
        p: 1,
        textAlign: "left",
        margin: "auto",
      }}
    >
      <Typography level="title-lg">{name}</Typography>
      <ContactRef contactRef={uniqueId} teamSlug={teamCtx.team.slug} />

      {contact && (
        <Stack gap={1}>
          {contact.contactMethods.map((method, index) => (
            <Chip
              key={index}
              variant="soft"
              sx={{ borderRadius: 4 }}
              startDecorator={
                iconMap[method.key] ?? <Message style={{ fontSize: 14 }} />
              }
            >
              {method.value}
            </Chip>
          ))}
          <Divider />
          {contact.addressLine1 && (
            <Typography level="body-sm">{contact.addressLine1}</Typography>
          )}
          {contact.addressLine2 && (
            <Typography level="body-sm">{contact.addressLine2}</Typography>
          )}
          <Stack direction="row" gap={1}>
            {contact.city && (
              <Typography level="body-sm">{contact.city}</Typography>
            )}
            {contact.state && (
              <Typography level="body-sm">{contact.state}</Typography>
            )}
            {contact.postalCode && (
              <Typography level="body-sm">{contact.postalCode}</Typography>
            )}
          </Stack>
          {contact.countryCode && <CountryFlag code={contact.countryCode} />}
        </Stack>
      )}
    </Box>
  );
}
