import { Box, Card, Chip, Divider, Stack, Typography } from "@mui/joy";
import { ContactTableItemFragment } from "../../../__generated__/types-and-hooks";
import { ReactNode } from "react";
import CountryFlag from "../../../components/CountryFlag";
import { useTranslation } from "../../../i18n";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";

export interface TableViewProps {
  contacts: ContactTableItemFragment[];
}

export interface ContactCardProps {
  contact: ContactTableItemFragment;
}

// Map of contact method keys to corresponding icons
const iconMap: Record<string, ReactNode> = {
  phone: <Phone style={{ fontSize: 14 }} />,
  email: <Email style={{ fontSize: 14 }} />,
  whatsapp: <WhatsApp style={{ fontSize: 14 }} />,
};

export function ContactCard({ contact }: ContactCardProps) {
  const t = useTranslation();
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Typography level="title-lg">{contact.name}</Typography>
        <Divider />
        <Typography level="title-sm">Contact Methods</Typography>
        {contact.contactMethods.map((method, index) => (
          <Chip
            key={index}
            variant="soft"
            sx={{ borderRadius: 4 }}
            startDecorator={
              iconMap[method.key] ?? <Message style={{ fontSize: 14 }} />
            }
          >
            {method.value}
          </Chip>
        ))}
        <Divider />
        <Typography level="title-sm">Address</Typography>
        <KeyValueLine
          label={t("Address Line 1")}
          value={contact.addressLine1}
        />
        <KeyValueLine
          label={t("Address Line 2")}
          value={contact.addressLine2}
        />
        <KeyValueLine label={t("City")} value={contact.city} />
        <KeyValueLine label={t("State")} value={contact.state} />
        <KeyValueLine label={t("Postal Code")} value={contact.postalCode} />
        <KeyValueLine
          label={t("Country")}
          value={
            contact.countryCode ? (
              <CountryFlag code={contact.countryCode} />
            ) : null
          }
        />
      </Card>
    </>
  );
}

function KeyValueLine({
  label,
  value,
}: {
  label: string;
  value?: string | null | ReactNode;
}) {
  if (!value) return null;
  return (
    <Stack direction="row" gap={1}>
      <Typography sx={{ minWidth: 140 }} level="body-md">
        {label}:
      </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
}

export default function ListView({ contacts }: TableViewProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 250px), 1fr))",
        gap: 2,
        marginBottom: 3,
      }}
    >
      {contacts.map((contact) => (
        <ContactCard key={contact.id} contact={contact} />
      ))}
    </Box>
  );
}
